import React from 'react';
import { useSearchParams } from "react-router-dom";
import logo from './logo.svg';
import './App.css';

function App() {
  const [templateId, setTemplateId] = React.useState('');
  // const [isOpen, setIsOpen] = React.useState(false);
  // const [userId, setUserId] = React.useState('');
  const getParams = (url = window.location) => {
    let params = {};
    new URL(url).searchParams.forEach(function (val, key) {
      if (params[key] !== undefined) {
        if (!Array.isArray(params[key])) {
          params[key] = [params[key]];
        }
        params[key].push(val);
      } else {
        params[key] = val;
      }
    });
    return params;
  }

  const iframeProp = React.createRef();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
  //   const urlParams = new Proxy(new URLSearchParams(window.location.search), {
  //     get: (searchParams, prop) => searchParams.get(prop),
  // });
    setTemplateId(urlParams.get('templateId'));
    const userId = urlParams.get('userId');
    const isOpen = urlParams.get('isOpen') ? true : false;
    // setUserId(urlParams.get('userId'));
    const params = getParams();
    delete params.templateId;
    if(params.userId) {
      delete params.userId;
    }
    if(params.isOpen) {
      delete params.isOpen;
    }

    const userObj = {
      id: userId === null ? generateUuid() : userId
    }

    window.addEventListener(
      'message',
      (event) => {
        if (event.data === 'uploadSuccessful') {
          window.location.reload()
        }
        if (event.data.type === 'upload-failed') {
          window.location.reload()
        }
      },
      false
    )

    const iframe = iframeProp.current;

    iframe.onload = function () {
      iframe.contentWindow.postMessage(
        {
          type: 'userConfig',
          user: userObj,
          pulter_metadata: params,
          data: {
            isOpen: isOpen,
            // dataSync: false,
            // webhookUrl: 'https://webhook.site/3ded5f24-1c6a-4ab6-bed3-bb88c0f12ccd'
          }
        },
        '*'
      )
    }
  },[]);

  const generateUuid = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    )
  };

  const holderStyle = {
    zIndex: 2147483647,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }

  const iframeStyle = {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px'
  }

  return (
      <div style={holderStyle}>
        <iframe
          title='Pulter Importer'
          ref={iframeProp}
          style={iframeStyle}
          // data-pulter-token={this.generateUuid()}
          // src={`http://localhost:6006/?templateId=${templateId}`}
          src={`https://portal.pulter.co/?templateId=${templateId}`}
          frameBorder='0'
        />
      </div>
  );
}

export default App;
